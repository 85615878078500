import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IMposfile} from 'app/blocks/model/mpos-file.model';
import {IPurchaseInvoice} from 'app/blocks/model/purchase-invoice.model';

export interface ICreatePurchaseInvoice extends IEntityAction {
    entity?: IPurchaseInvoice;
    uploadDocuments?: IMposfile[];
    deletedDocuments?: IMposfile[];
}

export interface IUpdatePurchaseInvoice extends IEntityAction {
    entity?: IPurchaseInvoice;
    uploadDocuments?: IMposfile[];
    deletedDocuments?: IMposfile[];
}

export class CreatePurchaseInvoice implements ICreatePurchaseInvoice {
    static readonly type = '[Purchase Invoice] Create Purchase Invoice';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IPurchaseInvoice,
        public uploadDocuments?: IMposfile[],
        meta?: ActionMeta
    ) {}
}

export class UpdatePurchaseInvoice implements IUpdatePurchaseInvoice {
    static readonly type = '[Purchase Invoice] Update Purchase Invoice';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IPurchaseInvoice,
        public uploadDocuments?: IMposfile[],
        public deletedDocuments?: IMposfile[],
        meta?: ActionMeta
    ) {}
}
